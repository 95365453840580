<template>
  <div>
    <b-card
      title="ค้นหารายการเเทงหวย"
    >
      <b-form
        style="max-width: 1024px;"
        @submit.prevent
      >
        <b-row class="mt-1 text-left text-md-center align-items-top align-content-start">
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="ยูสเซอร์เนม"
              label-for="mc-username"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="mc-username"
                v-model="form.username"
                placeholder="Username"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="ประเภทหวย"
              label-for="mc-type"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <v-select
                v-model="form.type"
                label="title"
                :options="gameTypes"
                placeholder="ประเภทหวย"
                @input="selectedType"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="ประเภทการเเทง"
              label-for="mc-type"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <v-select
                v-model="form.betType"
                label="ประเภทการเเทง"
                :clearable="false"
                :options="options"
                append-to-body
                placeholder="ประเภทการเเทง"
                @input="selectedBetType"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="เลข"
              label-for="mc-number"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="mc-number"
                v-model="form.number"
                placeholder="เลข"
                :minlength="numberLength"
                :maxlength="numberLength"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="วันที่เริ่มต้น"
              label-for="mc-start"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <flat-pickr
                v-model="startDate"
                class="form-control"
                :config="{ disableMobile:true,enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="วันที่สิ้นสุด"
              label-for="mc-ebd"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <flat-pickr
                v-model="endDate"
                class="form-control"
                :config="{ disableMobile:true,enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click.prevent="onSubmit"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card
      title=""
    >
      <b-table
        responsive
        :fields="columns"
        :per-page="pagination.rowsPerPage"
        :current-page="pagination.page"
        :items="onRequest"
        :filter="filter"
        bordered
      >
        <template #cell(username)="v">
          <span
            style="text-decoration: underline;cursor: pointer"
            class="font-weight-bolder"
            @click="showUserDetial(v.value)"
          >
            {{ v.value }}
          </span>
        </template>
        <template #cell(betNumber)="v">
          <div class="font-weight-bolder">
            {{ v.value }}
          </div>
        </template>
        <template #cell(status)="v">
          <div>
            <b-button
              v-if="v.value === -1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="cyan-7"
            >Refund
            </b-button>
            <b-button
              v-else-if="v.value === 0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="relief-secondary"
            >Waiting
            </b-button>
            <b-button
              v-else-if="v.value === 1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="success"
            >Win
            </b-button>
            <b-button
              v-else-if="v.value === 2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="danger"
            >Lose
            </b-button>
          </div>
        </template>
        <template #cell(payPercen)="v">
          <div class="font-weight-bolder">
            {{ v.value.toFixed(2) }}
          </div>
        </template>
        <template #cell(betAmount)="v">
          <div class="font-weight-bolder">
            {{ v.value.toFixed(2) }}
          </div>
        </template>
        <template #cell(winLose)="v">
          <div
            :class="textColorWithTwoColor(v.value)"
            class="font-weight-bolder"
          >
            {{ v.value.toFixed(2) }}
          </div>
        </template>
        <template #cell(rollingAmount)="v">
          <div>{{ v.value.toFixed(2) }}</div>
        </template>
        <template #cell(betType)="v">
          <div>{{ getBetType(v.item.huayId)[v.value - 1] }}</div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="2"
        >
          <b-form-group class="text-nowrap">
            <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
            <b-form-select
              v-model="pagination.rowsPerPage"
              size="sm"
              :options="rowsOptions"
              class="w-50"
              style="min-width: 100px"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="8"
          md="6"
          sm="12"
        >
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.rowsNumber"
            :per-page="pagination.rowsPerPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BFormSelect,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BRow,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import lottoService from '@/service/lottoService'
import flatPickr from 'vue-flatpickr-component'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    flatPickr,
    BTable,
    BCardBody,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormDatepicker,
    BPagination,
    BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        username: '',
        type: '',
        betType: '',
        number: '',
      },
      options: [
        'ทั้งหมด',
        'สามตัวบน',
        'สามตัวโต้ด',
        'สามตัวหน้า',
        'สามตัวท้าย',
        'สองตัวบน',
        'สองตัวล่าง',
        'วิ่งบน',
        'วิ่งล่าง',
      ],
      mapValue: {
        สามตัวบน: 1,
        สามตัวโต้ด: 2,
        สามตัวหน้า: 3,
        สามตัวท้าย: 4,
        สองตัวบน: 5,
        สองตัวล่าง: 6,
        วิ่งบน: 7,
        วิ่งล่าง: 8,
        เลขชุด: 9,
      },
      gameTypes: [
        'ทั้งหมด',
      ],
      gameTypesMapper: {},
      huayTypesMapper: {},
      rowsOptions: [5, 10, 15, 25, 50, 100, 250, 500, 'all'],
      type: '',
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 10,
      },
      columns: [
        {
          key: 'betTime',
          label: 'วัน/เวลา',
          class: 'text-nowrap',
        },
        {
          key: 'prefix',
          label: 'Prefix',
          class: 'text-nowrap text-right',
        },
        {
          key: 'username',
          label: 'Username',
          class: 'text-nowrap',
        },
        {
          key: 'huayName',
          label: 'Huay Name',
          class: 'text-nowrap',
        },
        {
          key: 'betType',
          label: 'Bet Type',
          class: 'text-nowrap',
        },
        {
          key: 'betNumber',
          label: 'Bet Number',
          class: 'text-nowrap text-center',
        },
        {
          key: 'betId',
          label: 'Bet ID',
          class: 'text-nowrap',
        },
        {
          key: 'roundId',
          label: 'Round ID',
          class: 'text-nowrap',
        },
        {
          key: 'payPercen',
          label: 'อัตราจ่าย',
          class: 'text-nowrap',
        },
        {
          key: 'status',
          label: 'Status',
          class: 'text-center text-nowrap',
        },
        {
          key: 'betAmount',
          label: 'Bet Amount',
          class: 'text-nowrap',
        },
        {
          key: 'winLose',
          label: 'Win/Lose',
          class: 'text-nowrap',
        },
      ],
      data: [],
      // startDate: this.$store.state.app.DateFormat(new Date(new Date().getTime() - 60 * 60000)),
      startDate: this.$store.state.app.DateFormat(new Date(new Date().setHours(0, 0, 0, 0))),
      endDate: this.$store.state.app.DateFormat(new Date()),
      filter: '',
      summation: {
        betAmount: 0,
        winLose: 0,
        rollingAmount: 0,
      },
      mapValueLimit: {
        สามตัวบน: 3,
        สามตัวโต้ด: 3,
        สามตัวหน้า: 3,
        สามตัวท้าย: 3,
        สองตัวบน: 2,
        สองตัวล่าง: 2,
        วิ่งบน: 1,
        วิ่งล่าง: 1,
        เลขชุด: 4,
      },
      betTypeText: [
        'สามตัวบน',
        'สามตัวโต้ด',
        'สามตัวหน้า',
        'สามตัวท้าย',
        'สองตัวบน',
        'สองตัวล่าง',
        'วิ่งบน',
        'วิ่งล่าง',
        'เลขชุด',
      ],
      betTypeTextYk: [
        'สามตัวบน',
        'สามตัวโต้ด',
        'สองตัวบน',
        'สองตัวล่าง',
        'วิ่งบน',
        'วิ่งล่าง',
        'เลขชุด',
      ],
      numberLength: 4,
    }
  },
  beforeMount() {
    this.filter = `&startDate=${this.startDate}&endDate=${this.endDate}`
  },
  async mounted() {
    lottoService
        .getAuthen('huay/dict')
        .then(res => {
          // eslint-disable-next-line no-restricted-syntax
          for (const v of res.data) {
            this.gameTypes.push(v.name)
            this.huayTypesMapper[v.name] = v.type
            this.gameTypesMapper[v.name] = v.id
          }
          lottoService
              .getAuthen('yeekee/dict')
              .then(res2 => {
                // eslint-disable-next-line no-restricted-syntax
                for (const v of res2.data) {
                  this.gameTypes.push(v.name)
                  this.huayTypesMapper[v.name] = v.type
                  this.gameTypesMapper[v.name] = v.id
                }
              })
        })
    if (this.$route.query.id !== undefined) {
      this.username = this.$route.query.id
    }
  },
  methods: {
    textColorWithTwoColor(v) {
      if (v < 0) {
        return 'text-danger'
      }
      if (v > 0) {
        return 'text-success'
      }
      return ''
    },
    selectedType() {
      this.form.number = ''
      if (this.form.type === 'ทั้งหมด') {
        this.form.betType = 'ทั้งหมด'
        return
      }
      const type = this.huayTypesMapper[this.form.type]
      if (type === 5) {
        this.options = [
          'เลขชุด',
        ]
        this.form.betType = 'เลขชุด'
        this.numberLength = 4
      } else if (type === 1) {
        this.numberLength = 3
        this.options = [
          'ทั้งหมด',
          'สามตัวบน',
          'สามตัวโต้ด',
          'สามตัวหน้า',
          'สามตัวท้าย',
          'สองตัวบน',
          'สองตัวล่าง',
          'วิ่งบน',
          'วิ่งล่าง',
        ]
        this.form.betType = 'ทั้งหมด'
      } else {
        this.numberLength = 3
        this.options = [
          'ทั้งหมด',
          'สามตัวบน',
          'สามตัวโต้ด',
          'สองตัวบน',
          'สองตัวล่าง',
          'วิ่งบน',
          'วิ่งล่าง',
        ]
        this.form.betType = 'ทั้งหมด'
      }
    },
    selectedBetType() {
      if (this.form.betType === 'ทั้งหมด') {
        this.numberLength = 3
      } else {
        this.numberLength = this.mapValueLimit[this.form.betType]
      }
      if (this.form.number.length !== this.numberLength) {
        this.form.number = ''
      }
    },
    getBetType(id) {
      return id.length === 24 ? this.betTypeTextYk : this.betTypeText
    },
    onSubmit() {
      const {
        username,
        type,
        betType,
        number,
      } = this.form

      this.filter = `&startDate=${this.startDate}&endDate=${this.endDate}`
      if (username !== '') {
        this.filter += `&username=${username}`
      }

      if (type !== '' && type !== 'ทั้งหมด') {
        this.filter += `&type=${this.gameTypesMapper[type]}`
      }

      if (betType !== '' && betType !== 'ทั้งหมด') {
        this.filter += `&betType=${this.mapValue[betType]}`
      }

      if (number !== '') {
        this.filter += `&number=${number}`
      }
    },
    // eslint-disable-next-line consistent-return
    async onRequest() {
      let {
        // eslint-disable-next-line prefer-const
        page,
        rowsPerPage,
        // eslint-disable-next-line prefer-const
        rowsNumber,
        // eslint-disable-next-line prefer-const
        sortBy,
        // eslint-disable-next-line prefer-const
        descending,
      } = this.pagination

      if (rowsPerPage === 'all') {
        rowsPerPage = 0
      }
      const startRow = (page - 1) * rowsPerPage
      const fetchCount = rowsPerPage === 0 ? rowsNumber : rowsPerPage

      this.loading = true

      const res = await lottoService.getAuthen(`bet?limit=${fetchCount}&page=${startRow}${this.filter}`)
      if (res.status === 200) {
        this.data = []
        this.summation = {
          betAmount: 0,
          winLose: 0,
          rollingAmount: 0,
        }
        if (res.data.todayList != null) {
          res.data.todayList.forEach(v => {
            // this.data.push(v)
            this.summation.betAmount += v.betAmount
            this.summation.winLose += v.winLose
            this.summation.rollingAmount += v.rollingAmount
          })
        }
        res.data.list.forEach(v => {
          // this.data.push(v)
          this.summation.betAmount += v.betAmount
          this.summation.winLose += v.winLose
          this.summation.rollingAmount += v.rollingAmount
        })

        this.pagination.page = page
        this.pagination.rowsPerPage = rowsPerPage
        this.pagination.rowsNumber = res.data.count
        this.pagination.sortBy = sortBy
        this.pagination.descending = descending
        this.loading = false

        return res.data.list
      }
    },
    showUserDetial(username) {
      this.$router.push(`members/edit?id=${username}`)
    },
    // refundBet(v) {
    //   this.$swal({
    //     dark: true,
    //     title: 'Are you sure?',
    //     text: 'คุณเเน่ใจว่าต้องการลบข้อมูลใช่หรือไม่',
    //     icon: 'info',
    //     showCancelButton: true,
    //     confirmButtonText: 'Ok',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //   })
    //       .then(async result => {
    //         if (result.value) {
    //           const res = await lottoService.getAuthen(`bet/refund?username=${v.username}&txId=${v.txId}`)
    //           if (res.status === 200) {
    //             const { bet } = res.data
    //             v.beforeBalance = bet.beforeBalance
    //             v.afterBalance = bet.afterBalance
    //             v.balance = bet.balance
    //             v.currentBalance = bet.currentBalance
    //             v.status = bet.status
    //
    //             this.$toast({
    //               component: ToastificationContent,
    //               position: 'top-right',
    //               props: {
    //                 title: 'success',
    //                 icon: 'CoffeeIcon',
    //                 variant: 'success',
    //                 text: res.data,
    //               },
    //             })
    //           } else {
    //             this.$toast({
    //               component: ToastificationContent,
    //               position: 'top-right',
    //               props: {
    //                 title: 'Error',
    //                 icon: 'AlertCircleIcon',
    //                 variant: 'danger',
    //                 text: res.data,
    //               },
    //             })
    //           }
    //         }
    //       })
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
